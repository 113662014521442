import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import FontPairingsForm from "./fontPairingForm";
import FontPairings from "./fontPairings";
import CollectionHistory from "./CollectionHistory";
import { UserContext } from "../../userContext";

const FontPairingsComponent = () => {
  const [fontPairings, setFontPairings] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [fontLinks, setFontLinks] = useState("");
  const [collections, setCollections] = useState([]);
  const [user] = useContext(UserContext);

  useEffect(() => {
    if (user) {
      const collectionsFromDatabase = user.fontCollections;
      setCollections(collectionsFromDatabase);
    }
  }, [user]);

  // example output of fontPAirings-
  //{
  //     "1": [
  //       "Roboto",
  //       "Roboto Mono",
  //       "Space Mono"
  //   ],
  //   "2": [
  //       "Orbitron",
  //       "Share Tech Mono",
  //       "Roboto Condensed"
  //   ],
  //   "3": [
  //       "Teko",
  //       "Inconsolata",
  //       "Anonymous Pro"
  //   ]
  // }

  function joinWithPlus(str) {
    return str.includes(" ") ? str.split(" ").join("+") : str;
  }

  useEffect(() => {
    if (fontPairings) {
      const fontSets = [];
      Object.values(fontPairings).map((set) => {
        fontSets.push(...set);
      });
      //fontSets array will contain all 9 fonts
      const fonts = [];
      fontSets.forEach((font) => {
        fonts.push(joinWithPlus(font));
      });

      setFontLinks(
        <link
          href={`https://fonts.googleapis.com/css2?family=${fonts.join(
            ":wght@400;700&family="
          )}&display=swap`}
          rel="stylesheet"
        />
      );
    }
  }, [fontPairings]);

  return (
    <>
      <Helmet>
        <title>TypeMatch - AI Font Pairing Generator</title>
        <meta
          name="description"
          content="Find perfect font pairings for your design projects using TypeMatch, powered by AI."
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <meta
          name="keywords"
          content="AI font pairing, font pair generator,font combination generator, font pairings generator,font combinations generator, font pairing ai, ai font picker, typography, AI fonts, AI typography, font generator, AI font generator, design tool"
        />
        <meta
          property="og:title"
          content="TypeMatch - AI Font Pairing Generator"
        />
        <meta
          property="og:description"
          content="Find perfect font pairings for your design projects using TypeMatch, powered by AI."
        />
        <meta property="og:image" content="/d512.png" />
        <meta
          property="og:url"
          content="https://www.designershangout.com/brainstorming/font-generator"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="TypeMatch - AI Font Pairing Generator"
        />
        <meta
          name="twitter:description"
          content="Find perfect font pairings for your design projects using TypeMatch, powered by AI."
        />
        <meta name="twitter:image" content="/d512.png" />
      </Helmet>
      {!submitted ? (
        <>
          <div className="container mx-auto px-4 py-8">
            <h1 className="text-6xl font-bold text-gray-900 mb-24 text-center poppins-bold">
              TypeMatch
            </h1>
            <FontPairingsForm
              setFontPairings={setFontPairings}
              setSubmitted={setSubmitted}
            />
            <CollectionHistory
              collections={collections}
              setSubmitted={setSubmitted}
              setFontPairings={setFontPairings}
            />
          </div>
        </>
      ) : (
        fontPairings && (
          <>
            <Helmet>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin="true"
              />
              {fontLinks}
            </Helmet>

            <FontPairings
              fontPairings={fontPairings}
              setFontPairings={setFontPairings}
              setSubmitted={setSubmitted}
              collections={collections}
              setCollections={setCollections}
            />
          </>
        )
      )}
    </>
  );
};

export default FontPairingsComponent;
