import React, { useState, useContext, useEffect, useCallback } from "react";
import ColorPaletteForm from "./colorPaletteForm";
import ColorPalettes from "./colorPalettes";
import CollectionHistory from "./CollectionHistory";
import { UserContext } from "../../userContext";
import { Helmet } from "react-helmet";

const ColorSchemes = () => {
  const [colorPalettes, setColorPalettes] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [collections, setCollections] = useState([]);

  const [user] = useContext(UserContext);

  let backendURL;
  useEffect(() => {
    backendURL =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_BACKEND
        : process.env.REACT_APP_BACKEND_LOCAL;
  });

  useEffect(() => {
    if (user) {
      const collectionsFromDatabase = user.colorCollections;
      setCollections(collectionsFromDatabase);
    }
  }, [user]);

  const saveCollectionsToBackend = useCallback(async (collections) => {
    try {
      const response = await fetch(`${backendURL}/color-palette/save`, {
        credentials: "include",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ collections }),
      });
      if (!response.ok) {
        throw new Error("Failed to save collections");
      }
    } catch (error) {
      console.error("Error saving collections to backend:", error);
    }
  });

  useEffect(() => {
    if (collections.length > 0) {
      try {
        saveCollectionsToBackend(collections);
      } catch (error) {
        console.log(error);
      }
    }
  }, [collections]);

  return (
    <div className="container mx-auto p-8 max-w-4xl">
      <Helmet>
        <title>Palette Pro - AI Color Palette Generator</title>
        <meta
          name="description"
          content="Effortlessly create visually appealing color palettes. Our tool uses advanced algorithms to generate harmonious color schemes, saving you time and boosting your creativity. Find the perfect colors for your website, logo, or any design project."
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <meta
          name="keywords"
          content="AI color palette, AI color schemes, AI colors, design tool, color generator, color schemes, ai color palettes, ai color scheme, ai color palette generator, palette generator, ai color schemes generator, color schemes generator, AI design"
        />
        <meta
          property="og:title"
          content="Palette Pro - AI Color Palette Generator"
        />
        <meta
          property="og:description"
          content="Generate AI-powered color palettes with Palette Pro to enhance your design projects."
        />
        <meta property="og:image" content="/d512.png" />
        <meta
          property="og:url"
          content="https://www.designershangout.com/brainstorming/color-schemes"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Palette Pro - AI Color Palette Generator"
        />
        <meta
          name="twitter:description"
          content="Generate AI-powered color palettes with Palette Pro to enhance your design projects."
        />
        <meta name="twitter:image" content="/d512.png" />
      </Helmet>
      <h1 className="text-6xl font-bold text-gray-900 mb-24 text-center poppins-bold">
        Palette Pro
      </h1>
      {!submitted ? (
        <>
          <ColorPaletteForm
            setColorPalettes={setColorPalettes}
            setSubmitted={setSubmitted}
          />
          <CollectionHistory
            collections={collections}
            setColorPalettes={setColorPalettes}
            setSubmitted={setSubmitted}
          />
        </>
      ) : (
        <ColorPalettes
          colorPalettes={colorPalettes}
          collections={collections}
          setCollections={setCollections}
          setSubmitted={setSubmitted}
        />
      )}
    </div>
  );
};

export default ColorSchemes;
