import React from "react";
import { Link } from "react-router-dom";

const Brainstorming = () => {
  return (
    <div className="min-h-screen py-16 px-6 lg:px-8 poppins-regular">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Brainstorming</h1>
      </div>
      <div className="grid gap-8 max-w-xl mx-auto">
        <div className="rest-card p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4 poppins-bold">
            Palette Pro
          </h2>
          <p className="text-gray-600 mb-4 poppins-regular">
            Generate stunning, harmonious color palettes tailored to your
            creative needs.
          </p>
          <Link to="/brainstorming/color-schemes">
            <button className="rounded-md px-6 py-3 text-base font-semibold text-white shadow-sm">
              Generate
            </button>
          </Link>
        </div>
        <div className="rest-card p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl text-gray-900 mb-4 poppins-bold">
            TypeMatch
          </h2>
          <p className="text-gray-600 mb-4 poppins-regular">
            Discover perfectly paired fonts to elevate your designs with style
            and clarity.
          </p>
          <Link to="/brainstorming/font-generator">
            <button className="rounded-md px-6 py-3 text-base font-semibold text-white shadow-sm">
              Find Fonts
            </button>
          </Link>
        </div>
        <div className="rest-card p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4 poppins-bold">
            Creative Map
          </h2>
          <p className="text-gray-600 mb-4 poppins-regular">
            Organize, brainstorm, and map out your design projects with ease and
            efficiency.
          </p>
          <Link to="/brainstorming/project-planner">
            <button
              className="cursor-not-allowed rounded-md px-6 py-3 text-base font-semibold text-white shadow-sm"
              disabled
            >
              {/* Start Plannig */}
              Coming Soon
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Brainstorming;
