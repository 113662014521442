import React, { useContext, useState, useEffect, useRef } from "react";
import DesignForm from "./DesignForm";
import DesignAnalysis from "./DesignAnalysis";
import DesignHistory from "./DesignHistory";
import { UserContext } from "../userContext";
import Loader from "../Loader";
import { Helmet } from "react-helmet";

const DesignCritique = () => {
  const [submitted, setSubmitted] = useState(false);
  const [history, setHistory] = useState([]);
  const historyRef = useRef(null);
  const [dataFromBackend, SetDataFromBackend] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [user] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [buttons, setButtons] = useState({
    previous: false,
    next: true,
    upload: false,
  });

  useEffect(() => {
    historyRef.current = history;
  }, [history]);
  let backendURL;
  useEffect(() => {
    backendURL =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_BACKEND
        : process.env.REACT_APP_BACKEND_LOCAL;
  });

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  });

  const loadProject = (project, index) => {
    updateButtons(index, project.followUp.length);
    let newData;
    setSubmitted(true);

    if (index < -1) {
      return;
    }
    if (index === -1) {
      newData = project.analysis;
    } else {
      newData = project.followUp[index].analysis;
    }

    newData.idForFollowUp = project._id;
    newData.followUpNumber = 2;
    SetDataFromBackend(newData);
  };

  const handlePrevious = async (index, id) => {
    if (index < 0) {
      return;
    }

    try {
      const project = historyRef.current.find((project) => project._id === id);
      loadProject(project, index - 2);
    } catch (error) {
      console.log(error);
    }

    if (index >= 1) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleNext = (index, id) => {
    const project = history.find((project) => project._id === id);
    loadProject(project, index);
    if (index < project.followUp.length) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const updateButtons = (index, length) => {
    if (length === 0) {
      setButtons({
        previous: true,
        next: true,
        upload: false,
      });
    } else {
      if (index > -1 && index + 1 < length) {
        setButtons({
          previous: false,
          next: false,
          upload: true,
        });
      }
      if (index === -1) {
        setButtons({
          previous: true,
          next: false,
          upload: true,
        });
      }
      if (index + 1 === length) {
        setButtons({
          ...buttons,
          next: true,
          upload: false,
          previous: false,
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Design Critique - AI Design Feedback Tool</title>
        <meta
          name="description"
          content="Get AI-powered feedback on your design projects with Design Critique to improve on your work iteratively."
        />
        <meta
          name="keywords"
          content="AI design feedback, design critique, design tools, design feedback, ai design critique, design review, ai design review, design feeback tool, ai design feedback tool, ai design review tool, design review tool, ai design review tool, ai design acritique tool, design critique tool, project improvement"
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <meta
          property="og:title"
          content="Design Critique - AI Design Feedback Tool"
        />
        <meta
          property="og:description"
          content="Get AI-powered feedback on your design projects with Design Critique to improve your work."
        />
        <meta property="og:image" content="/d512.png" />
        <meta
          property="og:url"
          content="https://designershangout.com/design-critique"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Design Critique - AI Design Feedback Tool"
        />
        <meta
          name="twitter:description"
          content="Get AI-powered feedback on your design projects with Design Critique to improve your work."
        />
        <meta name="twitter:image" content="/d512.png" />
      </Helmet>
      {!submitted ? (
        user ? (
          <div className="w-full h-full flex flex-col justify-center items-center my-20">
            <DesignForm
              setSubmitted={setSubmitted}
              SetDataFromBackend={SetDataFromBackend}
              setCurrentIndex={setCurrentIndex}
              setButtons={setButtons}
            />
            <DesignHistory
              SetDataFromBackend={SetDataFromBackend}
              setSubmitted={setSubmitted}
              setCurrentIndex={setCurrentIndex}
              history={history}
              setHistory={setHistory}
              setButtons={setButtons}
            />
          </div>
        ) : (
          <div className="m-auto h-1/3 w-1/4 rest-card p-16 flex flex-col items-center justify-center mt-16 gap-3">
            <h2 className="poppins-bold text-3xl">Not Logged In</h2>
            <button
              className=""
              onClick={() => {
                window.location.href = `${backendURL}/auth/google`;
                setLoading(true);
              }}
            >
              {loading ? <Loader /> : "Login"}
            </button>
          </div>
        )
      ) : (
        <div className="w-full h-full bg-white flex justify-center">
          <DesignAnalysis
            data={dataFromBackend}
            setCurrentIndex={setCurrentIndex}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            currentIndex={currentIndex}
            buttons={buttons}
            setButtons={setButtons}
            setSubmitted={setSubmitted}
            setHistory={setHistory}
            historyRef={historyRef}
          />
        </div>
      )}
    </>
  );
};

export default DesignCritique;
